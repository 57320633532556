/* estlint-ignore */

.Toastify__toast-container--top-center {
  padding: 16px 24px;
  width: auto;
}

@media (min-width: 1024px) {
  .Toastify__toast-container--top-center {
    left: auto;
    right: 1em;
    transform: none;
  }
}

@media (max-width: 1024px) {
  .Toastify__toast-container--top-center {
    width: 100vw;
  }
}

.Toastify__toast {
  min-height: 54px;
  padding: 8px 16px;
}

.Toastify__toast-body {
  margin: auto 0 auto 20px;
}

.Toastify__toast--success {
  background: #a7c823;
  color: #3b5225;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.Toastify__toast--success > .Toastify__toast-body::before {
  content: "";
  width: 24px;
  height: 100%;
  display: block;
  background: url("./icons/ico-success.svg") no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 0;
  left: 9px;
}

.Toastify__toast--success > .Toastify__close-button {
  height: 34px;
  color: #3b5225;
}

.Toastify__toast--error {
  background: #ef4444;
  color: #fff;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.Toastify__toast--error > .Toastify__toast-body::before {
  content: "";
  width: 24px;
  height: 100%;
  display: block;
  background: url("./icons/ico-error.svg") no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 0;
  left: 9px;
}

.Toastify__toast--error > .Toastify__close-button {
  height: 34px;
  color: #fff;
}

.Toastify__close-button > svg {
  height: 20px;
  width: 20px;
}

.Toastify__progress-bar {
  opacity: 0 !important;
}
