@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 300;
    src: url("SourceSansPro-Light.ttf");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    src: url("SourceSansPro-Regular.ttf");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    src: url("SourceSansPro-SemiBold.ttf");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: bold;
    src: url("SourceSansPro-Bold.ttf");
}

@font-face {
    font-family: "Source Sans Pro" ;
    font-style: italic;
    font-weight: bold;
    src: url("SourceSansPro-BoldItalic.ttf");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: normal;
    src: url("SourceSansPro-Italic.ttf");
}
