@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./fonts/SourceSansPro/style.css";
@import "./Toast/index.css";

html {
  background-color: #fff;
  color: #2d2d2d;
    font-family: "Source Sans Pro", serif;
}

a,
a:focus,
a:active {
  outline: none;
}
